<template>
  <div class="login-content d-flex flex-column pt-lg-0 pt-12 px-10">
    <!--begin::Logo-->
    <router-link :to="{ name: 'dashboard' }" v-slot="{ href, navigate }">
      <a :href="href" @click="navigate" class="login-logo pb-xl-20 pb-10">
        <img
          src="media/logos/logo-4.png"
          class="max-h-70px"
          alt="Central Core"
        />
      </a>
    </router-link>
    <!--end::Logo-->
    <!--begin::Signin-->
    <div class="login-form">
      <!--begin::Form-->
      <form
        class="form"
        novalidate="novalidate"
        id="kt_login_signin_form"
        @submit.stop.prevent="onSubmitLogin()"
      >
        <!--begin::Title-->
        <div class="pb-10 pb-lg-15">
          <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
            Se connecter
          </h3>
          <div class="text-muted font-weight-bold font-size-h4">
            Nouveau ?
            <router-link :to="{ name: 'register' }" v-slot="{ href, navigate }">
              <a
                :href="href"
                @click="navigate"
                class="text-primary font-weight-bolder"
                >Créer un compte</a
              >
            </router-link>
          </div>
        </div>
        <!--begin::Title-->
        <!--begin::Form group-->
        <div class="form-group">
          <label class="font-size-h6 font-weight-bolder text-dark"
            >Votre E-Mail</label
          >
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0"
            type="email"
            name="email"
            autocomplete="off"
            :value="model.email"
          />
        </div>
        <!--end::Form group-->
        <!--begin::Form group-->
        <div class="form-group">
          <div class="d-flex justify-content-between mt-n5">
            <label class="font-size-h6 font-weight-bolder text-dark pt-5"
              >Votre mot de passe</label
            >
            <router-link
              :to="{ name: 'forgotPassword' }"
              v-slot="{ href, navigate }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                >Mot de passe oublié ?</a
              >
            </router-link>
          </div>
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0"
            type="password"
            name="password"
            autocomplete="off"
            :value="model.password"
          />
        </div>
        <!--end::Form group-->
        <!--begin::Action-->
        <div class="pb-lg-0 pb-5">
          <button
            ref="kt_login_signin_submit"
            id="kt_login_singin_form_submit_button"
            class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
          >
            Se connecter
          </button>
        </div>
        <!--end::Action-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "login",
  data() {
    return {
      state: "signin",
      // todo Remove this dummy login info
      model: {
        email: "admin@demo.com",
        password: "demo"
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    onSubmitLogin() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send login request
          this.$store
            .dispatch(LOGIN, {
              email: this.model.email,
              password: this.model.password
            })
            // go to which page after successfully login
            .then(() => this.$router.push({ name: "dashboard" }))
            .catch(() => {});

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });
    }
  }
};
</script>
